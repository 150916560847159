
.popup-wrapper {
    z-index: 9000;
    position: fixed;
    top: 0;
    left:0;
    bottom:0;
    right: 0;
    background-color: rgba(0,0,0,0.4);

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-wrapper .popup-inner {

}

.popup-wrapper .popup-inner .popup {
    width: 428px;
    /*min-height: 494px;*/
    background-color: #FFF;
    border-radius: 4px;
    padding: 25px;

    display: grid;

    gap: 5px;

    position: relative;
}

.popup-wrapper .popup-inner .popup p, .popup-wrapper .popup-inner .popup button, .popup-wrapper .popup-inner .popup a {
    width: 100%;
}

.popup-wrapper .popup-inner .popup .popup-close {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 0;
    margin: 0;
    min-width: 22px;
    max-width: 22px;
    border-radius: 11px;
    width: 22px;
    height: 22px;
    font-size: 12px;
    font-weight: 700;
}


@media only screen and (max-width: 47.5em) {
    .popup-wrapper .popup-inner .popup {
        width: 90vw;
    }
}
